import styled from 'styled-components';

export const Container = styled.div`
  .task-container {
    background-color: transparent;

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed !important;
    }
  }

  .task-more-options {
    float: right;
    display: flex;
    position: relative;
    margin-bottom: -2rem !important;
    justify-content: flex-end;
    margin-top: 0.8rem;
    margin-right: 0.5rem;

    svg {
      cursor: pointer;
    }

    .task-more-options-dropdown {
      margin-left: 1rem;
      margin-top: -0.8rem;
    }
  }

  .task-card {
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: var(--body-background);
    border: 1px solid var(--card-task-border);
    border-radius: 4px;
    border-left-width: 4px;
    padding: 0.5rem 0.7rem;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    box-shadow: 0 5px 7.8px 0 rgb(0 0 0 / 25%), 0 0.6px 1.8px 0 rgb(0 0 0 / 23%);
    
    input {
      border: 1px solid var(--card-task-input-border);
      background-color: var(--body-background);
      color: var(--card-task-input-text);
      padding: 0.3rem 0.5rem;
      margin-bottom: 0.6rem;
      font-size: 0.9rem;

      &:hover, &:focus, &:active {
        border: 1px solid var(--card-task-border);
        background-color: var(--card-task-input-background);
      }
    }

    .task-title {
      color: var(--card-task-input-text);
      padding: 0.3rem 0;
      margin-bottom: 0.3rem;
      font-size: 0.7rem;
      font-weight: 700;
      line-height: 0.9rem;
      max-height: 3.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .task-index {
      color: var(--project-icon);
      font-size: 0.8rem;
      margin: 0 0.4rem 0 0;
    }

    .ant-progress-inner {
      background-color: var(--card-task-input-background);
    }
    
    .ant-progress-text {
      font-size: 0.8rem;
    }

    .info-icons {
      svg {
        margin-left: 0.4rem;
      }

      .date-info {
        font-size: 0.7rem;
        font-weight: normal;
        margin-right: -0.2rem;

        &.date-info- {
          &delayed {
            color: #f5222d;
            font-weight: bold;
          }

          &today {
            font-weight: bold;
            color: #a38f0d;
          }

          &intime {
            color: #33810c;
          }
        }
      }

      .date-info-start {
        color: #0c3381;
      }

      .calendar- {
        &delayed {
          svg {
            fill: #f5222d;
          }
        }

        &today {
          svg {
            fill: #facf14;
          }
        }

        &intime {
          svg {
            fill: #52c41a;
          }
        }

        &start {
          svg {
            fill: #0c3381;
          }
        }
      }
    }

    .user-and-effort {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.1rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;

      .active {
        color: #0a0;
      }

      .user-name {
        font-size: 0.7rem;
      }

      .anticon-play-circle, .anticon-pause-circle {
        svg {
          font-size: 18px;
        }
      }

      .effort {
        line-height: 0.9rem;
        font-weight: 700;
        margin-left: 0.4rem;
      }

      .user-select {
        width: calc(100% - 70px);
      }
    }
  }

  .comentary-icon {
    cursor: pointer;
    font-size: 1rem;

    svg {
      fill: var(--input-text-placeholder);
    }
  }

  .play-task-active {
    position: relative;
    font-size: 1.2rem;
    margin-bottom: -1.2rem;
    top: 0.7rem;
    left: -0.8rem;

    svg {
      fill: #0a0;
    }
  }

  .drop-area {
    border-bottom: 0.2rem solid var(--primary);
  }
`;
