import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input, Avatar, Select, Space, Tooltip, Skeleton, Tabs, Dropdown, Button, DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import PerfectScroll from 'react-perfect-scrollbar';
import ReactQuill from 'react-quill';
import moment from 'moment';

import Modal from '~/components/Modal';
import TaskHistory from '~/pages/TaskHistory';
import TaskComment from '~/pages/TaskComent';
import TaskWork from '~/pages/TaskWork';

import { taskType, taskState, avatarColors, taskTab } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { taskUpdateRequest } from '~/store/modules/task/actions';
import { taskCommentClean, taskCommentIndexRequest } from '~/store/modules/taskComment/actions';
import { taskHistoryClean, taskHistoryIndexRequest } from '~/store/modules/taskHistory/actions';
import { taskHourCreateRequest, taskHourUpdateRequest } from '~/store/modules/taskHour/actions';
import { sprintReadRequest } from '~/store/modules/sprint/actions';
import { userIndexRequest } from '~/store/modules/user/actions';

import 'react-quill/dist/quill.snow.css';
import { BugOutlined, SolutionOutlined, PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { Form } from './styles';

export default function Update({ id, projectId, sprintId, isVisible, setIsVisible, tabId, users }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const registry = useSelector(store => store.task.registry);
  const saving = useSelector(store => store.task.saving);
  const error = useSelector(store => store.sprint.error);
  const taskHour = useSelector(store => store.taskHour.playing);
  const taskHourLoanding = useSelector(store => store.taskHour.saving);

  const [avatar, setAvatar] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isClosing, setIsClosing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [playingId, setPlayingId] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const [quillContent, setQuillContent] = useState(null);

  const [form] = Form.useForm();
  const quillRef = useRef(null);

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  function onFinish(values) {
    let { userId, name, state, remainingWork, description, commentary, startAt, deadlineAt } = values;

    if (remainingWork) {
      const _d = remainingWork.split(':');

      const hr = Number(_d[0]);
      const min = Number(_d[1]);

      remainingWork = (hr * 60) + min;
    }

    dispatch(taskUpdateRequest({
      id,
      projectId,
      sprintId,
      userId,
      name,
      state,
      remainingWork,
      description,
      commentary,
      startAt: isNullOrEmpty(startAt) ? undefined : moment(startAt).format("YYYY-MM-DD HH:mm"),
      deadlineAt: isNullOrEmpty(deadlineAt) ? undefined : moment(deadlineAt).format("YYYY-MM-DD HH:mm")
    }));

    setIsSaving(isClosing);
  }

  function onPlay(projectId, taskId) {
    dispatch(taskHourCreateRequest({ projectId, taskId }));
    dispatch(sprintReadRequest(projectId, sprintId));
  }

  function onPause(id, projectId, taskId) {
    dispatch(taskHourUpdateRequest({ id, projectId, taskId }));
    dispatch(sprintReadRequest(projectId, sprintId));
  }

  const onButtonSaveClick = (e) => {
    switch (e.key) {
      case "1":
        setIsClosing(false);
        form.submit();
    }
  };

  useEffect(() => {
    dispatch(userIndexRequest());
  }, []);

  useEffect(() => {
    if (quillContent && quillRef.current) {
      quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(quillContent);
    }
  }, [quillContent]);

  useMemo(() => {
    setLoading(true);

    if (!isNullOrEmpty(registry?.id)) {
      dispatch(taskCommentIndexRequest(projectId, registry?.id, [{ page: 1 }, { pageSize: 10 }]));

      let remainingWork = undefined;
      if (registry.remainingWork) {
        let hr = Math.floor(registry.remainingWork / 60);
        if (hr < 10) hr = `0${hr}`;

        let min = registry.remainingWork - (Number(hr) * 60);
        if (min < 10) min = `0${min}`;

        remainingWork = `${hr}:${min}`;
      }

      form.setFieldsValue({
        userId: registry.userId,
        name: registry.name,
        type: taskType.find(a => Number(a.id) === Number(registry.type)),
        state: Number(registry.state),
        remainingWork: remainingWork,
        description: registry.description,
        commentary: '',
        startAt: isNullOrEmpty(registry.startAt) ? undefined : moment(registry.startAt),
        deadlineAt: isNullOrEmpty(registry.deadlineAt) ? undefined : moment(registry.deadlineAt)
      });

      setQuillContent(registry.description);

      setPlaying(Number(registry?.playing) === 1);

      if (Number(registry?.playingId) > 0) setPlayingId(registry?.playingId);
      else setPlayingId(taskHour?.timeId);

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isVisible) {
      setPlaying(Number(taskHour?.playing) === 1);

      if (Number(taskHour?.timeId) > 0) setPlayingId(taskHour?.timeId);
      else setPlayingId(registry?.playingId);
    }

    console.log({ taskHour });
  }, [taskHour]);

  useMemo(() => {
    if (isVisible) {
      setActiveKey(!tabId ? "main" : tabId);

      dispatch(taskCommentClean());
      dispatch(taskHistoryClean());
    }
  }, [isVisible]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);
      if (!error) setIsVisible(false);
    }

    if (!isClosing) {
      if (!error && !saving) form.setFieldValue('commentary', '');

      dispatch(taskCommentIndexRequest(projectId, registry?.id, [{ page: 1 }, { pageSize: 10 }]));
      dispatch(taskHistoryIndexRequest(projectId, registry?.id, [{ page: 1 }, { pageSize: 10 }]));
    }
  }, [saving]);

  useMemo(() => {
    var i = 0;

    var words = profile.name.split(' ');
    if (words.length <= 1) words = profile.name.split('_');
    if (words.length <= 1) words = profile.name.split('/');
    if (words.length <= 1) words = profile.name.split('|');
    if (words.length <= 1) words = profile.name.split('-');

    const initials = words.map(w => {
      if (i >= 2) return '';
      if (w.trim().length <= 0) return '';

      const letter = w.trim().substring(0, 1);

      if (!letter.match(/[a-z]/i)) return '';

      i++;

      return letter.toUpperCase();
    });

    const avatarColor = avatarColors[moment(profile.createdAt).unix() % avatarColors.length];

    setAvatar({ initials, color: avatarColor });
  }, [profile]);

  return (
    <Modal
      title={loading ? (<span>Editar tarefa</span>) : (
        <>
          {Number(form.getFieldValue('type')?.id) === 2
            ? (<BugOutlined style={{ color: form.getFieldValue('type')?.color }} />)
            : (<SolutionOutlined style={{ color: form.getFieldValue('type')?.color }} />)}

          <span style={{ marginLeft: 7 }}>{Number(form.getFieldValue('type')?.id) === 2 ? "Bug" : "Tarefa"} #{registry?.index}</span>

          <span className='v-separator'></span>

          {playing ? (
            <Tooltip title="Interromper contagem de tempo">
              <Button htmlType='button' disabled={taskHourLoanding} type='ghost' icon={<PauseCircleOutlined />} onClick={() => onPause(playingId, projectId, id)} className='active' />
            </Tooltip>
          ) : (
            <Tooltip title="Iniciar contagem de tempo">
              <Button htmlType='button' disabled={taskHourLoanding} type='ghost' icon={<PlayCircleOutlined />} onClick={() => onPlay(projectId, id)} />
            </Tooltip>
          )}
        </>
      )}
      visible={isVisible}
      setVisible={setIsVisible}
      maskClosable={false}
      width={800}
      bodyStyle={{ height: `calc(100vh - 150px ${isMobile ? ' - 60px' : ''})`, overflowY: 'auto' }}
      style={{ top: 0, margin: '10px auto' }}
      processing={saving}
      footer={(
        <Space style={{ padding: 8 }}>
          {activeKey === 'main' && (
            <Dropdown.Button
              type='primary'
              menu={{
                items: [{ key: '1', label: 'Salvar' }],
                onClick: onButtonSaveClick,
              }}
              loading={saving}
              onClick={() => { setIsClosing(true); form.submit(); }}
            >
              Salvar e Fechar
            </Dropdown.Button>
          )}

          <Button type="default" loading={saving} onClick={() => { setIsVisible(false) }}>Fechar</Button>
        </Space>
      )}
    >
      <div style={{ height: '100%' }}>
        <Tabs activeKey={activeKey} style={{ marginTop: -20 }} onChange={key => setActiveKey(key)}>
          {taskTab.map(t => {
            let _jsx = null;

            switch (t.key) {
              case 'comment':
                _jsx = <TaskComment users={users} taskId={registry?.id} projectId={projectId} />;
                break;

              case 'hour':
                _jsx = <TaskWork users={users} taskId={registry?.id} projectId={projectId} />;
                break;

              case 'history':
                _jsx = <TaskHistory users={users} taskId={registry?.id} projectId={projectId} />;
                break;

              default:
                _jsx = (
                  <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                      userId: undefined,
                      state: 0,
                    }}
                    onFinish={onFinish}>
                    <Row gutter={8}>
                      <Col xs={24} md={19}>
                        <Form.Item
                          label="Título"
                          name="name"
                          rules={[{ required: true, message: 'Título é obrigatório' }]}
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={5}>
                        <Form.Item
                          label="Status"
                          name="state"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <Select>
                              {taskState.map(e => (
                                <Select.Option key={e.id} value={e.id}>{e.description}</Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={9}>
                        <Form.Item
                          label="Responsável"
                          name="userId"
                          tooltip="Quem está designado para esta tarefa"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <Select>
                              {users?.map(u => (
                                <Select.Option key={u.id} value={u.id}>
                                  <Space>
                                    <Avatar size="18" icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                                      {u.short}
                                    </Avatar>
                                    <span>{u.name}</span>
                                  </Space>
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={3}>
                        <Form.Item
                          label="Esforço"
                          name="remainingWork"
                          tooltip="Horas estimadas"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={6}>
                        <Form.Item
                          label="Início"
                          name="startAt"
                          tooltip="Defina uma data de início desta tarefa"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={6}>
                        <Form.Item
                          label="Prazo"
                          name="deadlineAt"
                          tooltip="Defina uma data de entrega desta tarefa"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label="Descrição"
                          name="description"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <ReactQuill
                              ref={quillRef}
                              theme="snow"
                              modules={editorModules}
                              formats={editorFormats}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label={
                            <Tooltip title={`Comentar como ${profile.name}`}>
                              <Space>
                                <Avatar size="18" style={{ backgroundColor: avatar?.color, verticalAlign: 'middle' }}>
                                  {avatar?.initials}
                                </Avatar>
                                <span>Comentário</span>
                              </Space>
                            </Tooltip>
                          }
                          name="commentary"
                        >
                          {loading ? (
                            <Skeleton.Button active size="small" />
                          ) : (
                            <Input.TextArea rows={3} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )
                break;
            }

            return (
              <Tabs.TabPane tab={t.description} key={t.key}>
                {_jsx}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </Modal>
  );
}
