import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Avatar, Progress, Tooltip, Dropdown, Menu } from 'antd';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import { isNullOrEmpty } from '~/helpers/util';
import { taskType, taskState } from '~/helpers/lists';
import { handlePlaylist, handleTaskCounter } from './functions';

import { taskReadRequest, taskUpdateSprintRequest } from '~/store/modules/task/actions';
import { taskHourCreateRequest, taskHourUpdateRequest, taskHourTimecount } from '~/store/modules/taskHour/actions';
import { sprintReadRequest } from '~/store/modules/sprint/actions';

import {
  UserOutlined, PlayCircleTwoTone, PlayCircleOutlined, PauseCircleOutlined, BugOutlined, SolutionOutlined,
  HistoryOutlined, MoreOutlined, CommentOutlined, CalendarOutlined, CopyOutlined
} from '@ant-design/icons';
import { Container } from './styles';

export default function Card({ onDrop, e, step, id, setTaskId, projectId, levelAdm, otherSprints, users, inPlay, playlist, setPlaylist, setIsVisibleTaskUpdate, totalComments, setTabId, position, setPosition }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const sprint = useSelector(store => store.sprint.registry);
  const loading = useSelector(store => store.sprint.loading);
  const taskHoursSaving = useSelector(store => store.taskHour.saving);
  const taskHours = useSelector(store => store.taskHour.list);

  const [isDragging, setIsDragging] = useState(false);
  const [timecount, setTimecount] = useState(0);
  const [timecountText, setTimecountText] = useState();

  const cardRef = useRef(null);

  function openTask(e, tab = undefined) {
    setTaskId(e.id);
    dispatch(taskReadRequest(e));
    setIsVisibleTaskUpdate(true);
    setTabId(tab);
  }

  function onPlay(projectId, taskId) {
    setTimecount(e.worked);
    dispatch(taskHourCreateRequest({ projectId, taskId }));
    dispatch(sprintReadRequest(projectId, e.sprintId));
  }

  function onPause(id, projectId, taskId) {
    dispatch(taskHourUpdateRequest({ id, projectId, taskId }));
    dispatch(sprintReadRequest(projectId, e.sprintId));
  }

  function copyToClipboard(id, projectId, sprintId) {
    const rootUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    const permalink = `${rootUrl}/projects/${projectId}/sprints/${sprintId}/tasks/${id}`;

    navigator.clipboard.writeText(permalink).then(() => {
      toast.success('Permalink copiado');
    }).catch((err) => {
      toast.error('Erro ao copiar para a área de transferência: ' + err);
    });
  };

  function handleMouseDown() {
    setIsDragging(true);
  }

  function handleMouseMove(e) {
    if (isDragging) {
      const cardRect = cardRef.current.getBoundingClientRect();

      //      const newX = cardRect.left + e.clientX - (isNaN(position.x) ? 0 : position.x);
      //    const newY = cardRect.top + e.clientY - (isNaN(position.y) ? 0 : position.y);
      const newX = e.clientX;
      const newY = e.clientY;

      setPosition({ x: newX, y: newY, left: cardRect.left, top: cardRect.top, right: cardRect.right, bottom: cardRect.bottom });
    }
  }

  function handleMouseUp() {
    setIsDragging(false);
  }

  function handleDragStart(event) {
    const dragPreview = document.createElement('div');
    dragPreview.innerHTML = `<b>#${e.index}</b> - ${e.name}`;
    dragPreview.style.position = 'absolute';
    dragPreview.style.top = '-1000px';
    dragPreview.style.cursor = 'pointer';
    dragPreview.classList.add('card-drag-drop-copy');

    document.body.appendChild(dragPreview);
    event.dataTransfer.setDragImage(dragPreview, 0, 0);
    event.dataTransfer.setData('text/plain', e.id);
  }

  function handleDragEnter(e) {

  }

  function handleDragEnd(event) {
    event.preventDefault();

    const dragPreview = document.querySelector('.card-drag-drop-copy');
    if (dragPreview) {
      document.body.removeChild(dragPreview);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();

    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const cardRect = cardRef.current.getBoundingClientRect();

    if (
      mouseX >= cardRect.left &&
      mouseX <= cardRect.right &&
      mouseY >= cardRect.top &&
      mouseY <= cardRect.bottom
    ) {
      return;
    }

    const dropArea = document.getElementById('drop-area');
    if (dropArea) {
      dropArea.style.display = 'block';
      dropArea.style.left = mouseX + 'px';
      dropArea.style.top = mouseY + 'px';
    }
  }

  function handleDragLeave() {
    const dropArea = document.getElementById('drop-area');
    if (dropArea) {
      dropArea.style.display = 'none';
    }
  }

  useEffect(() => {
    let timerCount;

    if (playlist.filter((e) => e.active)?.length > 0) {
      timerCount = setInterval(() => {
        setTimecount((prevTimecount) => prevTimecount + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerCount);
    };
  }, [playlist]);

  useEffect(() => {
    const result = handleTaskCounter(timecount);
    setTimecountText(result);
    dispatch(taskHourTimecount(result));
  }, [timecount]);

  useMemo(() => {
    //  openTask({ id, projectId });
  }, [id]);

  useMemo(() => {
    const result = handlePlaylist(taskHours, setTimecount);
    setPlaylist(result);
  }, [taskHours]);

  let remainingWork = undefined;

  if (e.remainingWork) {
    const hr = Math.floor(Number(e.remainingWork) / 60);
    let min = Number(e.remainingWork) - (hr * 60);
    if (min > 0 && min < 10) min = `0${min}`;

    remainingWork = `${hr}h${min <= 0 ? '' : min}`;
  }

  const typeColor = taskType.find(a => Number(a.id) === Number(e.type))?.color;

  const detail = playlist.find(a => a.taskId === e.id);
  let worked = undefined;

  if (detail) {
    const hrs = Math.floor(detail.worked / 3600);
    let mins = Math.floor((detail.worked - (hrs * 3600)) / 60);
    let secs = detail.worked - (hrs * 3600) - (mins * 60);

    if (mins < 10) mins = `0${mins}`;
    if (secs < 10) secs = `0${secs}`;

    worked = `${hrs}:${mins}:${secs}`;
  }

  const progress = Math.floor(detail?.worked / detail?.effort * 100);
  let progressColor = progress < 100 ? '#1677ff' : '#87d068';

  let deadlineStatus = 0;
  const stateType = taskState.find(a => Number(a.id) === Number(step))?.type;

  const startAt = moment(e.startAt, 'YYYY-MM-DD HH:mm');
  const deadlineAt = moment(e.deadlineAt, 'YYYY-MM-DD HH:mm');
  const now = (stateType === 4 || stateType === 2) ? moment(e.updatedAt, 'YYYY-MM-DD HH:mm') : moment();

  if (deadlineAt.isBefore(now)) {
    deadlineStatus = 2;
  } else if (deadlineAt.isSame(now, 'day')) {
    deadlineStatus = 1;
  }

  const content = (
    <>
      <Dropdown
        placement='bottomRight'
        arrow
        overlay={() => (
          <Menu mode='vertical'>
            <Menu.SubMenu
              key={`${e.id}_1`}
              icon={<HistoryOutlined />}
              title="Mover para linha do tempo"
            >
              {otherSprints?.map(m => (
                <Menu.Item key={m.key}
                  disabled={profile.level < levelAdm}
                  onClick={() => dispatch(taskUpdateSprintRequest({
                    projectId,
                    taskId: e.id,
                    sprintId: m.key,
                    sprintOldId: sprint?.id
                  }))}
                >{m.name}</Menu.Item>
              ))}
            </Menu.SubMenu>

            <Menu.Item key={`${e.id}_2`} icon={<CopyOutlined />}
              onClick={() => { copyToClipboard(e.id, projectId, e.sprintId) }}
            >Copiar permalink</Menu.Item>

          </Menu>
        )}
        overlayClassName="task-more-options-dropdown"
      >
        <div className='task-more-options'>
          <MoreOutlined />
        </div>
      </Dropdown>

      <div className='task-card' style={{ borderColor: typeColor, opacity: Number(step) === 4 ? 0.6 : 1 }}>
        <span className='task-title' onClick={() => openTask(e)}>
          {Number(e.type) === 2
            ? (<BugOutlined style={{ color: typeColor, marginRight: 7 }} />)
            : (<SolutionOutlined style={{ color: typeColor, marginRight: 7 }} />)}
          <span className='task-index'>#{e.index}</span>
          <Tooltip title={e.name}>
            {e.name}
          </Tooltip>
        </span>

        <div className='user-and-effort' onClick={() => openTask(e)}>
          <Tooltip title={`${!users?.find(u => u.id === e.userId) ? "Nenhum usuário" : users?.find(u => u.id === e.userId)?.name}`}>
            <Avatar size="18" icon={!users?.find(u => u.id === e.userId) ? <UserOutlined /> : users?.find(u => u.id === e.userId)?.icon}
              style={{ backgroundColor: users?.find(u => u.id === e.userId)?.color, verticalAlign: 'middle' }}
            >{users?.find(u => u.id === e.userId)?.short}</Avatar>
            {inPlay === true && (
              <PlayCircleTwoTone className='play-task-active' />
            )}
          </Tooltip>

          <span className='info-icons'>
            {!isNullOrEmpty(e.deadlineAt) ? (
              <Tooltip title={`${deadlineStatus === 2 ? 'Entrega atrasada' : deadlineStatus === 1 ? 'Entrega próxima' : 'Entrega no prazo'}`} placement={Number(step === 4) ? 'topRight' : 'top'}>
                <span className={`date-info date-info-${deadlineStatus === 2 ? 'delayed' : deadlineStatus === 1 ? 'today' : 'intime'}`}>{moment(deadlineAt).format('DD/MM/YYYY HH:mm')}</span>
                <CalendarOutlined className={`comentary-icon calendar-${deadlineStatus === 2 ? 'delayed' : deadlineStatus === 1 ? 'today' : 'intime'}`} onClick={() => openTask(e)} />
              </Tooltip>
            ) : !isNullOrEmpty(e.startAt) ? (
              <Tooltip title="Início programado" placement={Number(step === 4) ? 'topRight' : 'top'}>
                <span className="date-info date-info-start">{moment(startAt).format('DD/MM/YYYY HH:mm')}</span>
                <CalendarOutlined className="comentary-icon calendar-start" onClick={() => openTask(e)} />
              </Tooltip>
            ) : null}

            {Number(totalComments) >= 1 && (
              <Tooltip title={`${totalComments} comentário(s)`} placement={Number(step === 4) ? 'topRight' : 'top'}>
                <CommentOutlined className='comentary-icon' onClick={() => openTask(e, 'comment')} />
              </Tooltip>
            )}
          </span>
        </div>

        <div className='user-and-effort'>
          <div>
            <Button type="link" shape="circle"
              disabled={taskHoursSaving || Number(step) === 4}
              icon={detail?.active === true ? (
                <Tooltip title="Contagem de tempo em execução">
                  <PauseCircleOutlined onClick={() => onPause(detail?.id, projectId, e.id)} />
                </Tooltip>
              ) : (
                <Tooltip title="Começar a contar o tempo">
                  <PlayCircleOutlined onClick={() => onPlay(projectId, e.id)} />
                </Tooltip>
              )}
              size="small"
              style={{ marginRight: '0.3rem' }}
            />
            <Tooltip title="Horas trabalhadas">
              <span className={detail?.active === true ? 'active' : ''}>{detail?.active === true ? timecountText : worked}</span>
            </Tooltip>
          </div>

          <Tooltip title="Esforço estimado" onClick={() => openTask(e)} placement={Number(step === 4) ? 'topRight' : 'top'}>
            {e.remainingWork && (
              <span className='effort'>
                {remainingWork}
              </span>
            )}
          </Tooltip>
        </div>

        {remainingWork && (
          <Progress percent={progress} status="active"
            strokeColor={{ '0%': progressColor, '100%': progressColor }}
          />
        )}
      </div>
    </>
  );

  return (
    <Container>
      <div
        id={e.id}
        disabled={loading}
        className={`task-container ${loading && 'disabled'}`}
        ref={cardRef}
        draggable={!isMobile}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnter={handleDragEnter}
      >
        {content}
      </div>
      <div id="drop-area" className="drop-indicator"></div>
    </Container>
  );
}
